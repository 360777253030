import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const useInternalPaths = () => {
  const {
    pages: { nodes },
  } = useStaticQuery(graphql`
    {
      pages: allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  return nodes.map(node => ({path: node.path, titulo: node.pageContext.titulo}))
}

const SecondPage = () => {
  const list = useInternalPaths()

  return <Layout>
    <h1>Hi from the second page</h1>
    <p>Pages</p>
    {list.map(n=><div><Link to={n.path}>{n.titulo}</Link></div>)}
    <Link to="/">Go back to the homepage</Link>
  </Layout>
}

export const Head = () => <Seo title="Page two" />

export default SecondPage
